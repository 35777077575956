import React from 'react';
import {
  Added,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="October 2018"
      subnav="release-notes">
      <div id="October2018" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          The scariest thing about this update is the fact that it’s already
          October. The list itself is light and, like the state of Nebraska,{' '}
          <Link
            href="https://www.washingtonpost.com/news/morning-mix/wp/2018/10/18/
          honestly-its-not-for-everyone-says-nebraskas-self-deprecating-new-tourism-campaign/?utm_term=.59125ffea6b5">
            not for everyone
          </Link>
          . Fans of the system banner and qa IDs are in luck, as are those who
          dig housekeeping—we went through to make sure what’s there still
          sparkles. For everything else, just know what’s in your brain is
          likely in ours, as well.
        </Paragraph>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                System banners. But not here—
                <Link href="https://sync.hudlnet.com/display/PF/System+Banners">
                  check Sync
                </Link>
                .
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.20.3"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.20.3"
            />

            <div className={styles.changesList}>
              <Added>
                <code>qaId</code> support to and form modifier.
              </Added>
              <Fixed>
                Incorrect passing of <code>qaId</code> in alerts and modals.
              </Fixed>
              <Fixed>
                A multi-notice situation in which dismissing one would also
                close the last one. Poor guy.
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="CSS"
              version="1.11.3"
              versionUrl="https://github.com/hudl/uniform-ui-css/releases/tag/v1.11.3"
            />

            <div className={styles.changesList}>
              <Fixed>The length of the hover transition on links.</Fixed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
